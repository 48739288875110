<template>
  <div class="default-layout relative bg-white min-h-screen">
    <BaseLoading :loading="isloading || appLoading" />
    <base-modal v-if="showModal" @modal-close="$emit('modal-close', false)">
      <slot name="modal"> </slot>
    </base-modal>
    <header v-if="showHeader">
      <slot name="header" class="header">
        <the-header :show-back-btn="showBackBtn" />
      </slot>
    </header>
    <main
      class="relative"
      :class="{
        'pb-14': showFooter,
      }"
    >
      <slot></slot>
    </main>
    <footer v-if="showFooter" class="footer relative">
      <slot name="footer">
        <the-footer />
      </slot>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
import TheHeader from '@/components/TheHeader.vue';
import TheFooter from '@/components/TheFooter.vue';
import BaseLoading from '@/components/Base/BaseLoadingApp.vue';
import BaseModal from '@/components/Base/BaseModal.vue';

export default defineComponent({
  name: 'DefaultLayout',
  components: {
    BaseModal,
    TheHeader,
    TheFooter,
    BaseLoading,
  },
  props: {
    msg: String,
    isloading: {
      type: Boolean,
      default: false,
    },
    showBackBtn: {
      type: Boolean,
      default: true,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      appLoading: 'getLoading',
    }),
  },
});
</script>

<style scoped lang="scss">
@import '../assets/scss/variables.scss';

.default-layout {
  width: 100%;
  max-width: 425px;
  margin: 0 auto;
  box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14),
    0 1px 8px 0 rgba(0, 0, 0, 0.12) !important;
  border: 1px solid #cacaca;
  height: 100vh;
  overflow-y: auto;
}

/*fixed*/
.footer {
  width: inherit;
  max-width: 425px;
  position: fixed;
  bottom: 0;
}
</style>
