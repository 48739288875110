<template>
  <transition name="fade">
    <div
      v-if="showModel"
      class="flex main-modal fixed w-full h-100 inset-0 z-50 overflow-hidden justify-center items-start"
      style="background: rgba(0, 0, 0, 0.7)"
      @click="modalClose"
    >
      <div
        class="content-model relative m-8 modal-container  w-11/12 md:max-w-md mx-auto shadow-lg z-50"
        @click.stop
      >
        <div class="bg-white modal-content overflow-y-auto rounded-xl">
          <!--Header-->
          <slot name="header" />
          <!--Body-->
          <slot />
          <!--Footer-->
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: 'BaseModal',
  props: {
    title: {
      type: String,
    },
    showModel: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    modalClose() {
      const modal = document.querySelector('.main-modal');
      modal.classList.remove('fadeIn');
      modal.classList.add('fadeOut');
      setTimeout(() => {
        modal.style.display = 'none';
      }, 500);
      this.$emit('modal-close', false);
    },
    handleClick() {
      console.log('handle click');
    },
  },
};
</script>
<style lang="scss" scoped>
.content-model {
  max-width: 400px;

  .modal-content {
    max-height: 100vh;
  }
}
</style>
