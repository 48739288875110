<template>
  <div
    class="app-header text-center font-bold bg-primary text-white w-full mr-auto relative flex items-center justify-center"
  >
    <router-link
      :to="{ name: constRouter.DASHBOARD.name }"
      class="link text-xl"
    >
      {{ title }}
    </router-link>
    <div
      class="flex text-white absolute top-1/2 left-2 transform -translate-y-1/2"
    >
      <button v-if="showBackBtn" @click="back()" class="focus:outline-none">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
    </div>
  </div>
</template>
<script>
import router from '@/plugins/router';
import constRouter from '@/constants/constRouter';

export default {
  name: 'TheHeader',
  props: {
    showBackBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    constRouter,
    title: 'Interloan',
  }),
  methods: {
    back() {
      router.back();
    },
  },
};
</script>

<style scoped lang="scss">
.app-header {
  top: 0;
  z-index: 1;
  height: 60px;
  max-width: 550px;
  border-radius: 0 0 20px 20px;
  line-height: 60px;
}
</style>
