<template>
  <div class="app-footer text-iconColor shadow-3 flex items-end justify-around">
    <router-link
      :to="{ name: constRouter.DASHBOARD.name }"
      class="flex flex-col items-center w-1/4"
      active-class="text-tertiary"
    >
      <span class="p-0.5">
        <svg
          v-if="$route.name === constRouter.DASHBOARD.name"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="1 1 18 18"
          fill="currentColor"
        >
          <path
            d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
          />
        </svg>
      </span>
      <p class="text-xs pb-0.5">Trang chủ</p>
    </router-link>
    <router-link
      :to="{ name: constRouter.DASHBOARD_SALARY_ADVANCE.name }"
      class="flex flex-col items-center w-1/4"
      active-class="text-tertiary"
    >
      <span class="p-0.5">
        <svg
          v-if="$route.name === constRouter.DASHBOARD_SALARY_ADVANCE.name"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="1 1 18 18"
          fill="currentColor"
        >
          <path
            d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"
          />
          <path
            fill-rule="evenodd"
            d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <p class="text-xs pb-0.5">Khoản ứng</p>
    </router-link>
    <span class=" w-1/4">
      <router-link
        :to="{ name: constRouter.BORROWER_MONEY.name }"
        class="flex flex-col items-center mx-auto
        inline-block plus-icon shadow-3 bg-primary hover:opacity-80 text-white rounded-full text-4xl text-center"
      >
        <span class="p-0.5">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7"
            fill="none"
            viewBox="2 2 19 19"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </span>
      </router-link>
    </span>
    <router-link
      :to="{ name: constRouter.DASHBOARD_MORE_INFO.name }"
      class="flex flex-col items-center w-1/4"
      active-class="text-tertiary"
    >
      <span class="p-0.5">
        <svg
          v-if="$route.name === constRouter.DASHBOARD_MORE_INFO.name"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="1 1 18 18"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      <p class="text-xs pb-0.5">Tìm hiểu</p>
    </router-link>
    <router-link
      :to="{ name: constRouter.DASHBOARD_PROFILE.name }"
      class="flex flex-col items-center w-1/4"
      active-class="text-tertiary"
    >
      <span class="p-0.5">
        <svg
          v-if="$route.name === constRouter.DASHBOARD_PROFILE.name"
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          viewBox="1 1 18 18"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
            clip-rule="evenodd"
          />
        </svg>
        <svg
          v-else
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
          />
        </svg>
      </span>
      <p class="text-xs pb-0.5">Tài khoản</p>
    </router-link>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import constRouter from '@/constants/constRouter';

export default defineComponent({
  name: 'TheFooter',
  data: () => ({ constRouter }),
});
</script>

<style scoped lang="scss">
.app-footer {
  height: 55px;
  width: inherit;
  border-radius: 20px 20px 0 0;
  background: white;
  padding-bottom: 5px;
}

.plus-icon {
  width: 54px;
  display: flex;
  height: 54px;
  border: 2px white solid;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -22px;
}
</style>
