<template>
  <div
    class="absolute h-full w-full -top-0.5 bg-loader"
    :class="loading ? '' : 'hidden'"
  >
    <span
      class="loader mx-auto my-0 inline-flex absolute top-1/2 left-1/2 transform -translate-x-1/2"
      :style="{
        width: `${size}px`,
      }"
    >
      <svg
        class="circular h-full w-full absolute top-0 bottom-0 left-0 right-0 m-auto"
        viewBox="25 25 50 50"
      >
        <circle
          class="path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          :stroke-width="width"
          stroke-miterlimit="10"
        />
      </svg>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'BaseLoadingApp',
  props: {
    size: {
      type: Number,
      default: 34,
    },
    width: {
      type: Number,
      default: 4,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped lang="scss">
$green: #008744;
$blue: #0057e7;
$red: #d62d20;
$yellow: #ffa700;
$white: #eee;
.bg-loader {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}
.loader {
  &:before {
    content: '';
    display: block;
    padding-top: 100%;
  }
}

.circular {
  animation: rotate 2s linear infinite;
  transform-origin: center center;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: $red;
  }
  40% {
    stroke: $blue;
  }
  66% {
    stroke: $green;
  }
  80%,
  90% {
    stroke: $yellow;
  }
}
</style>
